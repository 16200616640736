import React from "react"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import Privacy from "../sections/privacy"
import Footer from "../sections/footer"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Política de privacidade" />
    <Privacy />
    <Footer />
  </Layout>
)

export default PrivacyPage
