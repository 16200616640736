import React from "react"

import Header from "../../components/header"
import HeaderMobile from "../../components/headerMobile"

import { Container, Content, Text, Divisor } from "./styles"

export default function Terms() {
  return (
    <Container fluid>
      <Header title="Política de privacidade" />
      <HeaderMobile title="Política de privacidade" />
      <Content>
        <Text>
          <p>
            Por favor, recomendamos que a política de privacidade abaixo seja
            lida com atenção. Ela descreve como são tratadas as informações
            pessoais fornecidas por você ao acessar a plataforma Veriza. Ao ler
            o texto abaixo, você reconhece os termos e condições desta política
            de privacidade e concorda estar vinculado às suas condições de forma
            livre e espontânea.
          </p>
          <Divisor />
          <p>
            Esta Política de Privacidade esclarece quais informações pessoais
            podem ser acessadas e como elas serão utilizadas e armazenadas pela
            Veriza. A Veriza protege os direitos de privacidade dos usuários que
            se cadastrarem ou que de qualquer forma utilizem a Plataforma, sejam
            eles Tomadores ou Investidores (“Usuários”), nos termos da lei
            aplicável, desta Política de Privacidade, dos Termos de Cadastro,
            dos Termos de Uso Tomador, dos Termos de Uso Investidor (em
            conjunto, os “Termos de Uso Veriza”) e da Política de Avaliação de
            Crédito.
          </p>
          <p>
            Todas as informações que possam identificá-lo individualmente,
            direta ou indiretamente, incluindo suas informações de cadastro,
            dados armazenados em cookies e informações a respeito do seu acesso
            e utilização da Plataforma (incluindo informações de Protocolo de
            Internet) não serão utilizadas para finalidades diversas das
            constantes nesta Política de Privacidade, da Política de Avaliação
            de Crédito e demais Termos de Uso Veriza.
          </p>
          <h1>1. Plataforma</h1>
          <p>
            A Plataforma é um sistema criado e desenvolvido para interação entre
            os Usuários. Para realizar seu cadastro na Plataforma e assim
            desfrutar de todas as funcionalidades desta, Você deverá fornecer
            determinadas informações, tais como:
          </p>
          <p>
            i. seus dados pessoais, incluindo, mas não se limitando ao nome,
            endereço, telefone, e-mail, CPF e RG; ii. dados corporativos e
            financeiros necessários para análise, elaboração e gerenciamento de
            Propostas para Captação de Recursos e Propostas de Crédito, de
            acordo com os Termos de Uso Veriza.
          </p>
          <p>
            A Plataforma utiliza a tecnologia SSL da GeoTrust Ltd. para proteger
            a transmissão dos dados de identificação pessoal de seus Usuários.
            Esta tecnologia encripta as informações antes da transmissão pela
            internet entre o seu computador e os servidores da Plataforma,
            garantindo assim segurança de todos os seus dados cadastrais. Para
            maiores informações sobre o certificado de segurança GeoTrust,
            clique aqui.
          </p>
          <h1>2. Informações coletadas</h1>
          <p>
            A Veriza armazena seus dados pessoais, assim como dados de
            utilização, de relacionamento e perfil para a criação de
            estatísticas e gráficos, utilizados pela Veriza para aperfeiçoar e
            desenvolver uma melhor experiência para os Usuários no uso da
            Plataforma.
          </p>
          <p>
            A Veriza coleta e armazena as seguintes informações: (i) as
            informações voluntariamente fornecidas quando da realização do seu
            cadastro junto à Plataforma; (ii) informações financeiras advindas
            de uma ou mais instituições financeiras, do Brasil ou do exterior,
            incluindo, sem limitação, instituições financeiras (inclusive o
            Sistema de Informações de Crédito do Banco Central do Brasil),
            bureaus de crédito e quaisquer sistemas de pesquisa e análise, ou
            serviços e/ou repositórios de dados públicos ou privados aos quais a
            Veriza tenha acesso; (iii) informações decorrentes de sua utilização
            da Plataforma, o que inclui o seu endereço IP, as seções da
            Plataforma que Você acessou, tipo e linguagem do seu navegador; (iv)
            outros links de parceiros da Plataforma (aplicações de internet),
            disponibilizados por intermédio desta; (v) o dia e hora de seu
            acesso e atividades realizadas junto à Plataforma; (iv) qualquer
            conteúdo que Você tenha inserido na Plataforma ou comunicação que
            estabeleça com outros Usuários; (v) qualquer aceitação sua aos
            termos que governam sua relação com a Plataforma; e (vi) outras
            informações que permitam a Veriza melhorar a experiência dos
            Usuários na Plataforma.
          </p>
          <p>
            As informações armazenadas pela Veriza poderão ser fornecidas a
            terceiros, tais como instituições financeiras e demais agentes de
            mercado designados, na medida em que forem necessárias para a
            análise, elaboração e gerenciamento de Propostas de Captação de
            Recursos, para fins de gerar uma Proposta de Crédito, em consonância
            com a Política de Avaliação de Crédito, ou ainda para fins de
            eventuais parcerias entre a Veriza e bureaus de crédito, por
            exemplo.
          </p>
          <p>
            Relatórios estatísticos sobre o acesso, utilização da Plataforma e
            tendências de mercado, que não revelem a identidade dos Usuários,
            poderão também ser compartilhados com afiliados, subcontratados ou
            parceiros comerciais da Veriza. Inicialmente, as informações
            submetidas por Tomadores, de acordo com os Termos de Cadastro, serão
            utilizadas pela Veriza especificamente para fins de avaliação de
            crédito de Tomadores e manutenção de um banco de dados da Veriza.
            Essas informações não serão divulgadas a terceiros, exceto para
            parceiros da Veriza que colaborem com a referida avaliação de
            crédito do Tomador.
          </p>
          <p>
            Todavia, uma vez aceita uma Proposta de Crédito, de acordo com os
            Termos de Uso Tomador, as informações submetidas pelo Tomador
            ficarão disponíveis na Plataforma, para análise de Investidores e
            demais agentes envolvidos nas operações da Plataforma.
          </p>
          <p>
            Informações submetidas por Investidores, de acordo com os Termos de
            Uso Investidor, serão utilizadas exclusivamente pela Veriza e seus
            parceiros comerciais, para manutenção dos Serviços de Cobrança,
            gerenciamento de aplicações, recomendação de investimentos e
            manutenção de um banco de dados da Veriza.
          </p>
          <p>
            EM NENHUM CASO AS INFORMAÇÕES DOS USUÁRIOS SERÃO COMERCIALIZADAS
            JUNTO A TERCEIROS PELA VERIZA.
          </p>
          <h1>3. Atualização de Dados</h1>
          <p>
            Você poderá, a qualquer momento, alterar e/ou editar os seus dados
            cadastrais, apenas fazendo o login da sua conta, com seu nome de
            usuário e senha. Você é responsável pela veracidade, exatidão e
            atualização de referidos dados, conforme previsto nos Termos de Uso
            Veriza. PORÉM, A VERIZA NÃO DÁ QUALQUER GARANTIA DE QUE MANTERÁ O
            REGISTRO DE TODAS AS ATIVIDADES DE TODOS OS USUÁRIOS POR UM PERÍODO
            MÍNIMO, SALVO NA MEDIDA EM QUE TAL PROCEDIMENTO SEJA OBRIGATÓRIO POR
            LEI. A SENHA CADASTRADA É PESSOAL E INTRANSFERÍVEL, FICANDO A SUA
            GUARDA SOB A SUA RESPONSABILIDADE. O USO E A PROTEÇÃO DOS DADOS DE
            ACESSO À SUA CONTA SÃO DE SUA INTEIRA RESPONSABILIDADE, POR ISSO
            RECOMENDAMOS QUE VOCÊ GUARDE ESSES DADOS EM LOCAL SEGURO E NÃO OS
            FORNEÇA A TERCEIROS. A VERIZA NÃO SE RESPONSABILIZARÁ PELA
            UTILIZAÇÃO INADEQUADA DO SEU PERFIL E DA PLATAFORMA POR TERCEIROS
            QUE EVENTUALMENTE VENHAM A ACESSAR A SUA CONTA COM O SEU LOGIN E
            SENHA, OU MEDIANTE A VIOLAÇÃO DE SEGURANÇA DA PLATAFORMA.
          </p>
          <p>
            Toda comunicação entre Você e a Plataforma será feita pelo e-mail do
            Usuário cadastrado. Caberá ao Usuário informar à administração da
            Plataforma, por meio da interface própria da Plataforma para tal,
            acerca de quaisquer alterações no seu endereço de e–mail. Até que
            seja feita esta comunicação, serão válidos e eficazes os avisos e as
            comunicações enviados para o e-mail original constante no cadastro.
          </p>
          <h1>4. Comunicações</h1>
          <p>
            Quando qualquer Usuário se registra na Plataforma, ele receberá
            e-mails para confirmar ações e solicitações específicas. Você
            receberá notificações referentes a etapas importantes do seu
            relacionamento com a Veriza. Essas notificações transacionais não
            possuem opções de desligamento (opt-out), uma vez que são enviadas
            por medidas de segurança pela Plataforma. Poderemos também responder
            e-mails enviados por Usuários, se aplicável. Eventualmente
            enviaremos pesquisas e outras comunicações (diretamente ou por
            empresas parceiras) que julgarmos relevantes para Você, mas essas
            comunicações são de recebimento voluntário e Você poderá programar
            sua conta para não recebê-los (opt-out).
          </p>
          <h1>5. Identificação de Usuários</h1>
          <p>
            Os Usuários poderão ser identificados na Plataforma pelos nomes de
            usuário indicados no cadastro. Todavia, ao ofertar e/ou efetivar
            qualquer contratação com o auxílio da Plataforma, as informações
            pessoais de cadastro do Usuário tornar-se-ão visíveis aos demais
            Usuários e outros participantes da Plataforma (como as instituições
            financeiras que atuam em parceria com a Veriza ou mesmo os
            Investidores efetivos ou potenciais). Além disso, Você deve ter
            ciência de que as informações eventualmente disponibilizadas por
            Você na Plataforma em ambientes públicos desta são consideradas
            públicas e não gozam de qualquer prerrogativa ou expectativa de
            privacidade sob a lei aplicável ou sob a presente Política de
            Privacidade. Por isso, recomendamos aos Usuários que sejam
            criteriosos ao disponibilizar suas informações pessoais ou
            expressarem opiniões em ambientes públicos.
          </p>
          <h1>6. O que são “Cookies” e “Beacons”?</h1>
          <p>
            Ao navegar na Plataforma ou utilizar qualquer um de seus recursos ou
            ferramentas, um cookie será enviado para o seu computador. Um cookie
            é um fragmento de dado que o identifica como um usuário único,
            reconhecendo, acompanhando e armazenando as suas informações de
            navegação. Os cookies são utilizados para proporcionar a Você
            conteúdo personalizado, assim como para facilitar e agilizar o
            preenchimento de determinados campos. Caso Você configure seu
            navegador para recusar cookies, a Plataforma poderá não operar
            corretamente, ou ocasionar demasiada demora para carregar os
            conteúdos.
          </p>
          <p>
            Por sua vez, os beacons (também conhecidos como pixel tags ou clear
            gifs) servem para ajudar a gerenciar e aperfeiçoar nossos serviços,
            sistemas, publicidade e anúncios online e demais ferramentas.
            Beacons possibilitam o reconhecimento de um cookie de um navegador
            quando este visita o site no qual o beacon está localizado, e a
            registrar qual publicidade de banner leva o Usuário a determinado
            site.
          </p>
          <h1>7. Objetivo das Informações Coletadas</h1>
          <p>
            As informações coletadas pela Plataforma são armazenadas com o
            objetivo de: (i) caracterizar as suas preferências pessoais para
            aprimorar e customizar a sua experiência no uso da Plataforma; (ii)
            contatá-lo diretamente para comunicar sobre alterações das
            ferramentas e novas funcionalidades da Plataforma; (iii) analisar,
            elaborar e gerenciar suas Propostas para Captação de Recursos e as
            correspondentes Propostas de Crédito; (iv) gerenciar aplicações,
            recomendar investimentos e fazer cobranças; e (v) classificar e
            agrupar informações de Usuários, assim como consolidar dados
            estatísticos a respeito desses, inclusive para definir a composição
            da base de Usuários da Plataforma e o volume de tráfego em nossos
            servidores.
          </p>
          <p>
            O uso das informações coletadas e/ou armazenadas exige o
            processamento de dados que poderá ser feito pela própria Veriza ou
            por parceiros contratados para esse fim, no Brasil ou no exterior,
            que também estarão sujeitos a esta Política de Privacidade. Você
            expressamente concorda com a transferência desses dados a outros
            agentes de mercado, tais como instituições financeiras, que serão
            necessárias na intermediação e demais atividades relacionadas às
            transações realizadas na Plataforma. A Veriza emprega sistemas de
            segurança usualmente adotados pelo mercado para proteger suas
            informações contra acessos desautorizados, mas não garante que
            alguma informação pessoal não será acessada por terceiros não
            autorizados.
          </p>
          <p>
            Exceto conforme o previsto nesta Política de Privacidade e nos
            Termos de Uso Veriza, a Veriza não divulga a quaisquer terceiros as
            informações pessoais identificáveis fornecidas por Você. No entanto,
            caso seja solicitada por uma ordem expedida por autoridade
            competente no cumprimento de suas atribuições legais (tanto no
            Brasil como no exterior), ou em caso de violações ou suspeita de
            violações desta Política de Privacidade, dos Termos de Uso Veriza ou
            da lei aplicável, poderá ser requerido à Veriza disponibilizar as
            informações pessoais que estiverem armazenadas. A Veriza, porém, se
            compromete a revelar as informações limitando-se ao mínimo
            necessário para atingir as finalidades exigidas. As suas informações
            pessoais também poderão ser transferidas no caso de fusão, aquisição
            ou venda de ativos da Veriza. Caso o processamento das informações
            após estes eventos seja alterado, a Veriza notificará Você por meio
            do e-mail informado por Você em seu cadastro.
          </p>
          <h1>8. Alterações da Política de Privacidade</h1>
          <p>
            A presente Política de Privacidade poderá ser alterada a qualquer
            tempo, dispensada a sua notificação prévia, sendo sua
            responsabilidade visitar essa página de tempos em tempos para
            permanecer informado a respeito do tratamento que é conferido aos
            seus dados pessoais e da política de privacidade como um todo. Sem
            prejuízo do disposto, caso as alterações sejam significativas,
            colocaremos um aviso com mais destaque em nosso website, ou lhe
            enviaremos um e-mail. A sua contínua utilização da Plataforma
            significa sua aderência e concordância com a atual versão dessa
            Política de Privacidade.
          </p>
          <h1>9. Sites de terceiros</h1>
          <p>
            A PLATAFORMA PODERÁ FORNECER LINKS PARA OUTROS SITES DA INTERNET OU
            OUTROS RECURSOS. ASSIM, ESTES LINKS SERÃO DISPONIBILIZADOS COMO UMA
            CONVENIÊNCIA AOS USUÁRIOS E, COMO A VERIZA NÃO TEM CONTROLE SOBRE
            TAIS SITES OU RECURSOS EXTERNOS, O USUÁRIO RECONHECE E CONCORDA QUE
            A VERIZA NÃO É RESPONSÁVEL E NÃO ENDOSSA OU SE RESPONSABILIZA POR
            QUALQUER CONTEÚDO, PROPAGANDA, PRODUTOS, SERVIÇOS, ASSIM COMO PELOS
            TERMOS DE USO E POLÍTICAS DE PRIVACIDADE DESSES WEBSITES OU OUTROS
            MATERIAIS CONTIDOS OU DISPONIBILIZADOS POR INTERMÉDIO DESSES. NESTE
            SENTIDO, SUGERIMOS QUE O USUÁRIO SE INFORME SOBRE OS TERMOS DE
            USO,POLÍTICAS DE PRIVACIDADE E DEMAIS DOCUMENTOS QUE POSSAM REGER
            SEU ACESSO OU UTILIZAÇÃO DE SITES DE TERCEIROS ANTES DE FORNECER
            QUALQUER TIPO DE INFORMAÇÃO OU ACESSAR OU UTILIZAR QUALQUER RECURSO
            NESSES AMBIENTES. Sites, conteúdos e aplicativos de terceiros
            (incluindo, sem limitação, outros Usuários), assim como afiliados,
            subcontratados ou parceiros comerciais da Veriza poderão utilizar
            seus próprios cookies ou beacons, os quais podem não operar nos
            mesmos termos desta Política de Privacidade. Dessa forma, a Veriza
            não se responsabiliza por estes sites e conteúdos e, ainda, não
            compartilha, subscreve, monitora, valida ou aceita a forma como
            esses sites ou ferramentas de armazenamento de conteúdo coletam,
            processam e transferem suas informações pessoais. Recomendamos que
            Você consulte as respectivas políticas de privacidade de tais sites
            para se informar adequadamente a respeito do uso de suas informações
            pessoais por outros sites ou outras ferramentas.
          </p>
          <p>
            O Usuário expressamente reconhece e concorda que a Veriza não será
            responsável, direta ou indiretamente, por quaisquer perdas e danos
            que sejam efetiva ou alegadamente causados por, ou em conexão, pela
            confiança depositada nas informações, bens e serviços disponíveis no
            ou através de tais sites de terceiros.
          </p>
          <h1>10. Suporte</h1>
          <p>
            Em caso de maiores esclarecimentos ou quaisquer dúvidas sobre a
            Política de Privacidade, a Veriza está disponível para atendê-lo por
            meio de e-mail <span>ouvidoria@Veriza.com.br</span>.
          </p>
          <h1>11. Limitação de Responsabilidade</h1>
          <p>
            Os Usuários se responsabilizam civil e criminalmente pela veracidade
            dos dados fornecidos na Plataforma, bem como se comprometem a
            atualizar seus dados sempre que houver qualquer alteração.
          </p>
          <p>
            A VERIZA SE EXIME DE TODA E QUALQUER RESPONSABILIDADE PELOS DANOS E
            PREJUÍZOS DE QUALQUER NATUREZA QUE POSSAM DECORRER DO ACESSO,
            INTERCEPTAÇÃO, ELIMINAÇÃO, ALTERAÇÃO, MODIFICAÇÃO OU MANIPULAÇÃO,
            POR TERCEIROS NÃO AUTORIZADOS, DOS DADOS FORNECIDOS PELOS USUÁRIOS.
          </p>
          <h1>12. Geral</h1>
          <p>
            A proibição, invalidade ou inexequibilidade de qualquer disposição
            desta Política de Privacidade não invalidará ou afetará a mesma como
            um todo, devendo permanecer em vigor todas as demais disposições
            deste instrumento que não tenham sido afetadas pela proibição,
            invalidade ou inexequibilidade da cláusula inoperante.
          </p>
        </Text>
      </Content>
    </Container>
  )
}
