import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"

export const Container = styled(GridContainer)`
  padding-left: 0px;
  padding-right: 0px;
`

export const Content = styled(GridContainer)`
  display: flex;
  justify-content: center;
  background-color: #ffffff;
`

export const Text = styled.div`
  max-width: 1095px;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: justify;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};

  p {
    margin-bottom: 20px;
    margin-right: 35px;
    padding-left: 15px;
  }

  strong {
    font-weight: bold;
  }

  h1 {
    margin-top: 55px;
    margin-bottom: 15px;
    margin-right: 40px;
    padding-left: 15px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.6px;
    color: #125332;
  }

  span {
    color: ${props => props.theme.colors.greenDark};
  }
`

export const Divisor = styled.hr`
  background-color: #d8d8d8;
`
